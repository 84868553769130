<template>
  <div class="flex items-center gap-2 text-xs">
    <NuxtImg
      v-if="image"
      :src="image"
      :alt="name || ''"
      class="size-6 rounded-full object-cover"
    />
    <div class="flex flex-wrap gap-x-2 gap-y-1">
      <div
        v-if="name"
        class="font-extrabold"
      >
        {{ name }}
      </div>
      <div
        v-if="date"
        class="font-semibold uppercase tracking-wider text-gray-400"
      >
        <BaseDateTime
          :relative="hours < 12"
          :format="{year: 'numeric', month: 'long', day: 'numeric'}"
          :value="date"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {DateTime} from 'luxon';

const props = defineProps<{
  image?: string,
  name?: string,
  date?: string,
}>();

const hours = ref();
if (props.date) {
  const dateTime = DateTime.fromISO(props.date);
  const diff = DateTime.local().diff(dateTime, ['hours']).shiftTo('hours');
  hours.value = diff.hours;
}

</script>
