<template>
  <article class="group/item flex h-full flex-col rounded-3xl bg-white p-3 @container">
    <div class="relative">
      <client-only>
        <UiButtonLike
          class="absolute right-2 top-2 z-10"
          :favorite="articleIsFavorite"
          @click.stop="toggleFavorite"
        />
      </client-only>
      <NuxtLink
        :to="{name: 'artikelen-slug', params: {slug: article.slug}}"
        class="relative block"
      >
        <NuxtPicture
          v-if="article.active_image"
          :src="article.active_image.url"
          :sizes="`100vw sm:330px md:458px lg:${large ? '460' : '290'}px`"
          class="aspect-h-3 aspect-w-5 block overflow-clip rounded-3xl"
          :img-attrs="{class: 'w-full h-full object-cover group-hover/item:scale-[1.02] transition', alt: article.title}"
        />
        <div
          v-else
          class="aspect-h-3 aspect-w-5 overflow-clip rounded-3xl bg-[#D9D9D9]"
        />
        <div class="absolute inset-7 hidden border border-white @[380px]:block" />
      </NuxtLink>
    </div>

    <div class="flex grow flex-col p-6 @[380px]:p-8">
      <div class="5 mb-3 flex flex-wrap gap-1">
        <UiTag
          v-if="article.categories?.length > 0"
          to="/categorie"
          :label="article.categories[0]?.name"
        />
        <UiTag
          v-if="article.bedrijf"
          label="Gesponsord"
          highlight
        />
      </div>
      <NuxtLink
        :to="{name: 'artikelen-slug', params: {slug: article.slug}}"
        class="flex h-full flex-col justify-between space-y-3"
      >
        <div class="flex h-full flex-col justify-between">
          <h1 class="as-h3 mb-1.5 text-xl">
            {{ article.title }}
          </h1>
          <SofieText
            class="prose line-clamp-3 text-sm text-gray-600"
            :value="article.excerpt"
          />
        </div>
        <ArticleMetadata
          :image="article.author?.avatar"
          :name="article.author?.name"
          :date="article.publish_at ?? article.created_at"
        />
      </NuxtLink>
    </div>
  </article>
</template>

<script lang="ts" setup>
import type {ArticlesQuery} from '~/graphql/graphql';

const props = defineProps<{
  article: NonNullable<ArticlesQuery['articles']>['data'][number];
  large: boolean;
}>();

const {toggleFavoriteMutation, isFavorite} = useFavorites();
const {mutate: mutateToggleFavorite} = toggleFavoriteMutation;

const articleIsFavorite = isFavorite(props.article.id, 'Artikel');

const user = useSofieUserWhenAuthenticated();

function toggleFavorite() {
  if (!user.value) {
    return navigateTo({name: 'auth-login'});
  }

  mutateToggleFavorite({
    id: props.article.id,
    type: 'Artikel',
  });
}
</script>
